import React from "react"
import { Link } from "gatsby"
import { Location } from "@reach/router"

import PrimaryMenu from "../components/primary-menu"

import Logo from "../images/svg/logo.svg"

const Header = () => {
  return (
    <header className="">
      <div className="secondary-header" />
      <nav className="navbar">
        <div>
          <Link to="/" className="navbar-brand-wrapper">
            <div className="navbar-brand">
              <Logo width="150" className="d-inline-block align-top" />
            </div>
            <div className="navbar-title">
              <h1>
                Paul Roth <span>&amp; fils</span>
              </h1>
              <span>Sanitaire - Chauffage - Couverture</span>
            </div>
          </Link>
          <PrimaryMenu />
        </div>
      </nav>
    </header>
  )
}

export default props => (
  <Location>
    {locationProps => <Header {...locationProps} {...props} />}
  </Location>
)

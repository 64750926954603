import React from "react"
import { StaticQuery, graphql } from "gatsby"
import FooterMenu from "./footer_menu"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__wrapper content__container">
        <div className="footer__item">
          <h4>Paul Roth et Fils Obernai</h4>
          <p>
            7 Rue du Général Gouraud
            <br />
            67210 Obernai
          </p>
          <p>
            Tel: <a href="tel:+33388956144">03 88 95 61 44</a>
          </p>
        </div>
        <div className="footer__item">
          <h4>Paul Roth et Fils Illzach</h4>
          <p>
            8 Avenue d'Italie
            <br />
            68110 Illzach
          </p>
          <p>
            Tel: <a href="tel:+33389310801">03 89 31 08 01</a>
          </p>
        </div>
        <div className="footer__item">
          <StaticQuery
            query={graphql`
              query FooterMenuQuery {
                allWordpressWpApiMenusMenusItems(
                  filter: { slug: { eq: "menu-footer" } }
                ) {
                  edges {
                    node {
                      id
                      name
                      items {
                        wordpress_id
                        order
                        wordpress_parent
                        title
                        url
                        attr
                        target
                        classes
                        xfn
                        description
                        object_id
                        object
                        object_slug
                        type
                        type_label
                      }
                    }
                  }
                }
              }
            `}
            render={data => <FooterMenu data={data} />}
          />
        </div>
      </div>
      <div className="footer__copyright">
        <div className="content__container">
          © 2019 Copyright Paul Roth et Fils
        </div>
      </div>
    </footer>
  )
}

export default Footer

import React, {useState, useEffect} from "react"
import {graphql, StaticQuery} from "gatsby";
import MenuItem from "./menu-item"

const Menu = ({data}) => {
    const [toggled, setToggled] = useState(false)
    const [menuRef, setMenuRef] = useState(null)
    const [menuClasses, setMenuClasses] = useState(['primary_nav'])
    const menuItems = data.allWordpressWpApiMenusMenusItems.edges[0].node.items

    useEffect(() => {
        document.removeEventListener('click', handleClick, false)
    }, []);

    const handleClick = (e) => {
        if(menuRef.contains(e.target)) {
            return
        }

        closeMenu()
    }

    const openMenu = () => {
        setToggled(true)
        setMenuClasses(['primary_nav', '-open'])
        document.addEventListener('click', handleClick, false)
    }

    const closeMenu = () => {
        setToggled(false)
        setMenuClasses(['primary_nav'])
        document.removeEventListener('click', handleClick, false)
    }

    const handleMenuToggle = () => {
        if(!toggled) {
            openMenu()
        } else {
            closeMenu()
        }
    }

    return (
        <div className="primary_nav__wrapper">
            <button className={`hamburger hamburger--spin ${toggled? 'is-active': ''}`}
                    type="button"
                    aria-label="Menu"
                    aria-controls="navigation"
                    aria-expanded={!!toggled}
                    onClick={ () => handleMenuToggle() }
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
            <ul className={menuClasses.join(' ')} ref={node => setMenuRef(node)}>
                {menuItems.map((item, idx) => (
                    <MenuItem item={item} key={idx}/>
                ))}
            </ul>
        </div>
    );
}

const PrimaryMenu = () =>{

        return (

            <StaticQuery
                query={graphql`
                  query PrimaryMenuQuery {
                    allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "menu-principale"}}){
                      edges{
                        node{
                          id
                          name
                          items {
                            wordpress_id
                            order
                            wordpress_parent
                            title
                            url
                            attr
                            target
                            classes
                            xfn
                            description
                            object_id
                            object
                            object_slug
                            type
                            type_label
                            
                          }
                        }
                      }
                    }
                  }
                `}
                render={data =>
                    <Menu data={data} />
                }
            />
        )
}

export default PrimaryMenu
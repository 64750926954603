import React from "react"
import { Link } from "gatsby"

const FooterMenu = ({ data }) => {
  const footerMenuItems =
    data.allWordpressWpApiMenusMenusItems.edges[0].node.items
  return (
    <ul>
      {footerMenuItems &&
        footerMenuItems.map((item, idx) => (
          <li key={idx}>
            <Link to={item.url}>{item.title}</Link>
          </li>
        ))}
    </ul>
  )
}

export default FooterMenu

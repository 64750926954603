import {Link} from "gatsby";
import React, {useState} from "react";

const MenuItem = ({item}) => {

    const [toggled, setToggled] = useState(0);
    const [parentClasses, setParentClasses] = useState(['nav-item', 'dropdown']);
    const [childClasses, setChildClasses] = useState(['dropdown-menu']);

    const renderDropdown = (children) => {
        return (
            <div className={childClasses.join(' ')} aria-labelledby="navbarDropdownMenuLink">
                {children.map((child) =>
                    <Link className="dropdown-item" to={child.url} key={child.url}>
                        {child.title}
                    </Link>
                )}
            </div>
        )
    }

    const handleToggle = () => {
        setToggled(toggled? 0 : 1);
        setParentClasses(toggled? ['nav-item', 'dropdown'] : ['nav-item', 'dropdown', 'show']);
        setChildClasses(toggled? ['dropdown-menu'] : ['dropdown-menu', 'show']);
    }

    if (item.wordpress_children) {
        return (
            <li className={parentClasses.join(' ')} key={item.url}>
                <Link className="nav-link dropdown-toggle" id="navbarDropdownMenuLink"
                      role="button" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded={toggled? 'true' : 'false'} to={item.url}
                      onClick={() => handleToggle()}
                >
                    {item.title} <span className="sr-only">(current)</span>
                </Link>
                {renderDropdown(item.wordpress_children)}
            </li>
        )
    }

    return (
        <li className="primary_nav__item" key={item.url}>
            <Link to={item.url} className="nav-link">
                {item.title} <span className="sr-only">(current)</span>
            </Link>
        </li>
    )
}

export default MenuItem


